
    import { Vue, Component } from "vue-property-decorator";

    import db from "./../../db";
    import store from "./../../store";
    import { querySearchToObject } from "@/common/helpers/convert";

    @Component({})
    export default class NylasWebHook extends Vue {

        async addEmailSettings(emailSettings): Promise<void> {
            emailSettings.ID = emailSettings.ID.toLowerCase()
            return await db
                .collection(`/tenants/${store.state.tenantID}/settings/email/addresses`)
                .doc(emailSettings.ID)
                .set(emailSettings)
                .then(() => {})
                .catch(() => {});
        }

        async mounted() {
            const querySearch: string = window.location.search;

            if(querySearch) {
                const params: Record<string, any> = querySearchToObject(querySearch);

                const settings = {
                    ID: params['email_address'],
                    nylasAccessToken: params['access_token'],
                    allowedUsers: []
                };

                await this.addEmailSettings(settings);
            }

            this.$router.push({ path: '/settings/email-configuration' });
        }
    }
