import { Timestamp } from '@firebase/firestore';


export function querySearchToObject(querySearch) {
  const result = {};

  querySearch
  .split('&')
  .map(el => {
    const key = el.split('=')[0].replace('?', '');
    const val = el.split('=')[1].replace('%40', '@');

    result[key]= val;
  });

  return result;
}

export function aggregationFiltersWrapper(query, filters, module) {
  if (!filters || !filters.length) {
    return query;
  }

  const result = {
    aggs: {}
  };
  const range = {
    range: {}
  };

  const getRanges = (interval) => {
    const date = new Date();
    let dateOfStart;
    const dateOfEnd = Timestamp.now();

    switch (interval) {
      case '1w':
        // 1 week in ms = 1000 * 60 * 60 * 24 * 7 = 604800000
        dateOfStart = Timestamp.fromMillis(dateOfEnd.toMillis() - 604800000);
        break;
      case '1m':
        date.setMonth(date.getMonth() - 1);
        dateOfStart = Timestamp.fromMillis(date);
        break;
      case '1y':
        date.setFullYear(date.getFullYear() - 1);
        dateOfStart = Timestamp.fromMillis(date);
        break;
    }

    return { from: dateOfStart.seconds, to: dateOfEnd.seconds };
  };

  filters.map(filter => {
    if (filter.key === 'timeRange') {
      range.range = {
        field: `${module}_${filter.field}.seconds`,
        ranges: [
          { ...getRanges(filter.formattedValue) },
        ],
      };
    }
  });

  result.aggs['agg-filters'] = { ...query, ...range };

  return result;
}
